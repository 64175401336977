// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@font-face {
  font-family: Neothic;
  src: url(/fonts/Neothic.woff);
}

@font-face {
  font-family: 'Roboto Slab';
  src: url(/fonts/RobotoSlab-VariableFont_wght.woff);
}

// Define the theme object.
$angelssword-rpg-theme: mat.define-theme((color: (theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
      ),
      typography: (brand-family: 'Roboto Slab',
        plain-family: 'Roboto Slab'
      ),
      density: (scale: 0,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($angelssword-rpg-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
@include mat.typography-hierarchy($angelssword-rpg-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($angelssword-rpg-2-theme);
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto Slab";
}

@import 'vanilla-cookieconsent/dist/cookieconsent.css';
